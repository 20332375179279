import Vue from "vue";

/*TITLE*/
document.title = "Residencial Botanic | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Botanic";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Botanic";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "ac4-home-salzburg-193x1383--20230113120143.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "vela-natural-596x596-vela-natural-316x90-deco-bremen-roble-natural-316x90--20230113120101.jpg";
Vue.prototype.$image_bath2 = "nantes-acero-443x443-nantes-acero-333x592-mosaico-nantes-acero-333x592--20230113120141.jpg";
Vue.prototype.$image_room = "ac4-home-salzburg-193x1383-d--20230113120109.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "ac4-home-salzburg-193x1383--20230113120143.jpg",
  },
  {
    src: "ac4-home-kaprun-193x1383--20230113120152.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "vela-natural-596x596-vela-natural-316x90-deco-bremen-roble-natural-316x90--20230113120101.jpg",
  },
  {
    src: "bottega-caliza-443x443-bottega-caliza-333x592-spiga-bottega-caliza-333x596--20230113120148.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "nantes-acero-443x443-nantes-acero-333x592-mosaico-nantes-acero-333x592--20230113120141.jpg",
  },
  {
    src: "bath-3-urban-natural-nature-443x443-urban-natural-nature-333x592-limit-urban-natural-nature-333x592--20230113120102.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "ac4-home-salzburg-193x1383-d--20230113120109.jpg",
  },
  {
    src: "ac4-home-kaprun-193x1383-d--20230113120115.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = false;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/ikasaclassbotanic/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=28760%20tres%20cantos%20madrid",
    text: "28760 Tres Cantos, Madrid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=sector%20de%20los%20pueblos%2044%20local%2016%2028760%20tres%20cantos%20madrid",
    text: "Sector de los Pueblos 44 Local 16, 28760 Tres Cantos, Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:722325923",
    text: "722325923",
  },
  {
    icon: "mdi-email",
    link: "mailto:residencialbotanic@ikasa.es",
    text: "residencialbotanic@ikasa.es",
  },
];
